<template>
	<div>
		<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
			<div class="row">
				<div class="col-md-12 text-right">
					<a-button class="btn btn-info" icon="plus" @click="onAddRecord">Nuevo Producto</a-button>
				</div>
			</div>
			<div class="row pt10">
				<div class="col-md-12">
					<a-input-search v-model="searchText" ref="searchInput" placeholder="Ingresa SKU o nombre de producto para buscar en el catálogo" enter-button @search="onSearch" />
				</div>
			</div>
			<div class="row pt20" v-if="productsList.length">
				<div class="col-md-12">
					<a-table :columns="columns" :dataSource="productsList" rowKey="id">
						<div slot="product_label" slot-scope="record">
							{{ record.label }}
							<div v-if="record.in_s2r_shop">
								<a-tag color="purple">
									<a :href="getWCLink(record.wc_path)" target="new">Link tienda S2R</a>
								</a-tag>
							</div>
						</div>
						<span slot="normal_price" slot-scope="text, record">
							{{ getSalePrice(record.prices) }}
						</span>
						<span slot="active" slot-scope="record">
							{{ record.stock }}
							<br />
							<a-tag color="green" v-if="record.active">Activo</a-tag>
							<a-tag color="red" v-else>Inactivo</a-tag>
						</span>
						<span slot="action" slot-scope="record">
							<a-button class="btn btn-info" size="small" icon="printer" @click="onPrintLabel(record)" />
							<a-button class="ml7" type="info" size="small" icon="edit" @click="onEditRecord(record.id)" />
							<a-button class="ml7" size="small" :icon="record.active ? 'minus-circle' : 'plus-circle'" @click="onToggleRecord(record.id, record.active)" />
						</span>
					</a-table>
				</div>
			</div>
		</a-spin>
		<div id="printLabel" style="align: center" v-show="false">
			<table border="0" width="325mm">
				<tr>
					<td colspan="2" align="center">
						<barcode :value="selectedProduct.sku" width="2" height="45" fontSize="12"></barcode>
					</td>
				</tr>
				<tr>
					<td width="70mm" style="padding-left: 2mm">
						<img src="resources/images/logoS2R.png" width="50mm" />
					</td>
					<td align="center">
						<span style="font-size: 12pt !important; font-weight: bold">{{ numeral(selectedProduct.sale_price).format('$0,0.00') }}</span>
						<br />
						<span style="font-size: 10pt !important">
							{{ selectedProduct.label }}
						</span>
					</td>
				</tr>
			</table>
		</div>
	</div>
</template>

<script>
//
import Vue from 'vue'
import { mapGetters } from 'vuex'
import Swal from 'sweetalert2'
import _ from 'lodash'
import numeral from 'numeral'
import VueBarcode from 'vue-barcode'
import VueHtmlToPaper from 'vue-html-to-paper'
import utilities from '@/services/utilities'

const options = {
	name: '_blank',
	specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
	styles: ['https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css', 'resources/css/printLabels.css'],
	timeout: 1000, // default timeout before the print window appears
	autoClose: true, // if false, the window will not close after printing
	windowTitle: window.document.title, // override the window title
}

Vue.use(VueHtmlToPaper, options)

export default {
	name: 'productsList',
	components: {
		barcode: VueBarcode,
	},
	data() {
		return {
			searchText: '',
			columns: [
				{
					title: 'Nombre del producto',
					scopedSlots: { customRender: 'product_label' },
				},
				{
					title: 'Precio de venta',
					align: 'center',
					scopedSlots: { customRender: 'normal_price' },
				},
				{
					title: 'Stock/Estatus',
					scopedSlots: { customRender: 'active' },
					align: 'center',
				},
				{
					title: 'Acciones',
					scopedSlots: { customRender: 'action' },
					align: 'right',
				},
			],
			apiURL: process.env.VUE_APP_API_URL,
			selectedProduct: {
				label: '',
				sku: '',
				sale_price: '',
			},
		}
	},
	destroyed() {
		this.$store.commit('products/SET_STATE', {
			productsList: [],
		})
	},
	mounted() {
		this.$refs.searchInput.focus()
	},
	computed: {
		...mapGetters('user', ['user']),
		...mapGetters('products', ['productsList']),
		spinnerLoaderLabel() {
			return this.$store.state.products.spinnerLoaderLabel
		},
		spinnerLoader() {
			return this.$store.state.products.spinnerLoader
		},
	},
	methods: {
		numeral,
		onAddRecord() {
			this.$router.push('/warehouse/products/create')
		},
		onEditRecord(id) {
			this.$store.dispatch('products/GET_ONE', { id })
		},
		onToggleRecord(id, status) {
			Swal.fire({
				title: 'Atención',
				text: `¿Desea ${status ? 'deshabilitar' : 'habilitar'} el registro?`,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Sí, continuar',
				cancelButtonText: 'Cancelar',
			}).then((result) => {
				if (result.isConfirmed) {
					this.$store.dispatch('products/TOGGLE', id)
				}
			})
		},
		onSearch() {
			if (this.searchText == '') {
				Swal.fire({
					title: 'Catálogo de Productos',
					text: 'Debes ingresar texto para la búsqueda',
					icon: 'warning',
					confirmButtonText: 'Ok',
				})
			} else {
				this.$store.dispatch('products/SEARCH', { searchText: this.searchText })
			}
		},
		onPrintLabel(record) {
			let prices = JSON.parse(record.prices)

			this.selectedProduct = {
				label: record.label,
				sku: record.sku,
				sale_price: prices.sale_price,
			}
			setTimeout(() => {
				this.$htmlToPaper('printLabel')
			}, 100)
		},
		getSalePrice(record) {
			let prices = JSON.parse(record)
			return numeral(prices.sale_price).format('$0,0.00')
		},
		getWCLink(path) {
			if (path.includes('variations')) {
				path = utilities.replaceText(path.split('variations')[0], '/', '')
			}
			return `https://s2r.mx/?page_id=${path}`
		},
	},
}
</script>
